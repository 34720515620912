const labsData = [
  [
    { tw: "NFC Badge 電子名片", en: "NFC Name Card" },
    "/images/nfctogo.png",
    "https://badge.nfctogo.com/",
    {
      id: "nfc_to_go",
      images: ["/images/nfctogo.png"],
      title: "NFC Badge 電子名片",
      description: `透過整合網站、APP 及 NFC 卡片打造完整的電子名片體驗。一碰即連，讓您的資訊傳遞更有效率且經驗。
      
      功能特色：
      1. 電子名片持有方透過專屬 APP 設定名片資訊
      2. 名片收受方僅需使用支援 NFC 之手機即可感應取得名片，不需安裝 APP
      `,
      tech: "ReactJS,GaysbyJS,AWS,MongoDB,NFC",
      link: [
        ["https://www.nfctogo.com/", "電子名片官方網站"],
        ["https://www.youtube.com/@NFCTOGO", "Youtube Channel"],
        ["https://shopee.tw/washow", "購買連結"],
      ],
    },
  ],
  [
    { tw: "BakerShell 烘焙 LINE 電商", en: "BakerShell LINE EC" },
    "/images/labs-bakershell.webp",
    "https://www.bakershell.com.tw/",
    {
      id: "bakershell_line_ec",
      images: [
        "https://www.revtel.tech/pd/bs1.jpg",
        "https://www.revtel.tech/lp/bs1.jpg",
      ],
      title: "BakerShell 焙殼烘焙 LINE 電商 ",
      description: `透過一頁式網站及 LINE@ 整合，提供烘焙業最輕量化及最合適之電商。會員獨立於 LINE 之外，避免帳戶資訊被綁定。`,
      tech: "ReactJS,GaysbyJS,AWS,MongoDB",
      link: [["https://www.bakershell.com.tw/", "來去逛逛"]],
    },
  ],
  [
    { tw: "NFT 圖像合成器", en: "NFT image generator" },
    "/images/labs-nftfarm.webp",
    "https://nft-assets-builder.netlify.app/",
    {
      id: "nftfarm_generator",
      images: ["https://www.revtel.tech//pd/nft1.jpeg"],
      title: "NFT 圖像生成器",
      description: `純前端工具。透過圖層疊加一次性產生大量 PFP 式 NFT 素材`,
      tech: "ReactJS,GaysbyJS,AWS",
      link: [["https://nft-assets-builder.netlify.app/", "來去逛逛"]],
    },
  ],
  [
    { tw: "FreeStay 雲端排號工具", en: "FreeStay cloud Queueing assistant" },
    "/images/labs-freestay.webp",
    "https://freestay.revtel.app/",
    {
      id: "freestay_online_queue",
      images: [
        "https://freestay.revtel.app/static/FreeStayMock-4bedf80b0a7f9c9b44b7d1a23b1220ab.png",
      ],
      title: "FreeStay 線上排號",
      description: `透過 LINE 帳號整合，讓店家可以輕易於線上揭示目前店內號碼到幾號，降低客戶等待時間及提升體驗`,
      tech: "ReactJS,GaysbyJS,AWS,MongoDB",
      link: [["https://freestay.revtel.app/", "來去逛逛"]],
    },
  ],

  [
    { tw: "ReactConf.tv 影音管理", en: "ReactConf.tv video collection" },
    "/images/labs-reactconf.webp",
    "https://reactconf.tv/",
    {
      id: "reactconf_tv",
      images: ["https://www.revtel.tech/images/reactconf-tv.jpg"],
      title: "ReactConf.tv",
      description: `純前端影音開源專案。透過整合 youtube 及 vimeo 等影音方案，搭配 GatsbyJS 靜態技術打造影音互動站之基底`,
      tech: "ReactJS,GaysbyJS,AWS,",
      link: [["https://reactconf.tv/", "來去逛逛"]],
    },
  ],
  [
    { tw: "react-native-nfc-manager", en: "react-native-nfc-manager" },
    "/images/labs-rn-nfc.webp",
    "https://github.com/revtel/react-native-nfc-manager",
    {
      id: "react_native_nfc_manager",
      images: ["https://www.revtel.tech/images/react-native-nfc-manager.png"],
      title: "react-native-nfc-manager",
      description: `結合過往物聯網及 React Native 經驗打造生態系中 NFC 管理方案`,
      tech: "React Native,NFC,Kotlin,Swift",
      link: [
        ["https://github.com/revtel/react-native-nfc-manager", "來去逛逛"],
      ],
    },
  ],
  [
    { tw: "NFC 控制 APP", en: "NFC controll APP" },
    "/images/labs-rn-nfc-rw.webp",
    "https://github.com/revtel/react-native-nfc-rewriter",
    {
      id: "react_native_nfc_rewriter",
      images: [
        "https://www.revtel.tech/images/react-native-nfc-rewriter.png",
        "https://www.revtel.tech/images/nfc-rewriter.jpg",
      ],
      title: "react-native-nfc-rewriter",
      description: `react-native-nfc-manager 為 React Native 中 NFC 之管理方案，react-native-nfc-rewriter 為使用該 library 之 sample project`,
      tech: "React Native,NFC",
      link: [
        [
          "https://opengraph.githubassets.com/07d34a886c434d91316051c1f270a16c79a8cecbbd22ac38a505954af66dac22/revtel/react-native-nfc-rewriter",
          "來去逛逛",
        ],
        ["iOS", "https://apps.apple.com/tw/app/nfc-rewriter/id1551243964"],
        [
          "Android",
          "https://play.google.com/store/apps/details?id=com.washow.nfcopenrewriter",
        ],
      ],
    },
  ],
  [
    { tw: "印刷模數計算器", en: "Print industry module caculator" },
    "https://www.sam-huang.info/images/module-calc.png",
    "https://l.revtel.app/module-calc/",
    {
      id: "print_industry_module_calculator",
      images: ["https://www.sam-huang.info/images/module-calc.png"],
      title: "印刷模數計算器",
      description: "線上工具。協助印刷產業快速線上計算模數",
      tech: "ReactJS,GaysbyJS,AWS",
      link: [["https://l.revtel.app/module-calc/", "來去逛逛"]],
    },
  ],
];

module.exports = labsData;
exports.default = labsData;
